import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fluid-container"
}
const _hoisted_2 = {
  key: 1,
  class: "fluid-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroLandingPage = _resolveComponent("HeroLandingPage")!
  const _component_Jumbotron = _resolveComponent("Jumbotron")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!
  const _component_FAQ = _resolveComponent("FAQ")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_HeroLandingPage, {
      title: "Casino Landing Page",
      description: "This is a sample external page for after you click the button links on the website custom post types (casino, games, etc.)",
      "purchase-link": "https://modeltheme.com/go/purchase-coinflip-theme/",
      youtubeLink: "//www.youtube.com/embed/_GhNZrBbWfQ?autoplay=1"
    }),
    (!_ctx.sectionLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SectionWithImage, {
            class: "special-container",
            thumbnail: _ctx.section.thumbnail
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Jumbotron, {
                title: _ctx.section.title,
                subtitle: _ctx.section.subtitle,
                description: _ctx.section.description,
                buttonName: _ctx.section.buttonName,
                href: 'contact'
              }, null, 8, ["title", "subtitle", "description", "buttonName"])
            ]),
            _: 1
          }, 8, ["thumbnail"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loadingFaq)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_SectionWithImage, {
            class: "special-container",
            thumbnail: _ctx.faq.thumbnail,
            rtl: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_FAQ, {
                items: _ctx.faq.faqList
              }, null, 8, ["items"])
            ]),
            _: 1
          }, 8, ["thumbnail"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
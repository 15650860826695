
import { defineComponent } from "vue";
import HeroLandingPage from "@/components/HeroLandingPage/HeroLandingPage.vue";
import fetchSectionWithImage from "../misc/composition/fetchHomSectionWithImage";
import FAQ from "../components/FAQ/FAQ.vue";
import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";
import SectionWithImage from "../components/SectionWithImage/SectionWithImage.vue"
import Jumbotron from "@/components/Jumbotron/Jumbotron.vue";

export default defineComponent({
  components: { HeroLandingPage, FAQ, SectionWithImage, Jumbotron },
  setup() {
    const { section, loading: sectionLoading } = fetchSectionWithImage();
    const { faq, loading: loadingFaq } = fetchHomeFAQ();
    document.title = "Casino Landing - Coinflip";

    return {section, sectionLoading, faq, loadingFaq};
  },
});
